import React, { useEffect, useState } from 'react';
import Heading from '../../CommonComponents/Heading/Heading';
import Description from '../../CommonComponents/Description/Description';
import Button from '../../CommonComponents/Button/Button';
import { transformWidgetProps } from '@/components/WidgetMaker/utils/common';
import { getStyleForProps } from '@/components/DesignSystem/ModernTheme/utils/getStylesConfig';
import { renderMediaComponent } from '@/components/DesignSystem/ModernTheme/utils/common';

import {
  HeroBannerParent,
  TopSection,
  ButtonContainer,
  BottomSectionBanner,
  LeftContainer,
  RightContainer,
  BannerContainer,
} from './ModernHeroBanner4.styled';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFormWidget } from '@/redux/actions';
import { useSSRSelector } from '@/redux/ssrStore';

function ModernHeroBanner4(props) {
  const { storeData } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
  }));
  const { widgetData } = useSelector((state) => ({
    widgetData: state.widgetsReducer,
  }));
  const [heroBannerState, setHeroBannerState] = useState(
    transformWidgetProps(props?.config?.children)
  );
  const [styleConfig] = useState({
    config: {
      bannerAspectRatio: {
        desktop: 768,
        mobile: 768,
      },
      rotationSpeed: 3,
    },
  });
  const dispatch = useDispatch();
  const openForm = () => {
    dispatch(toggleFormWidget(true));
  };

  const [propStyle, setPropStyle] = useState(storeData?.theme?.style_template);

  useEffect(() => {
    if (props?.config?.children) {
      setHeroBannerState(transformWidgetProps(props?.config?.children));
    }
  }, [props?.config?.children]);

  useEffect(() => {
    if (widgetData?.activeStyleTemplate) {
      setPropStyle(getStyleForProps(widgetData?.activeStyleTemplate));
    } else {
      setPropStyle(getStyleForProps(storeData?.theme?.style_template));
    }
  }, [widgetData?.activeStyleTemplate, storeData?.theme?.style_template]);
  return (
    <HeroBannerParent
      id="MT__HEROBANNER4"
      className={`modern_theme_hero_banner4 bg_type_${heroBannerState?.media?.background?.type}`}
      styleConfig={styleConfig?.config}
      propsBackground={heroBannerState?.media?.background}
      themeBackground={propStyle?.backgroundColor}
    >
      {heroBannerState?.media?.background?.type === 'image' &&
      heroBannerState?.media?.background?.content[0]?.url.length > 0 ? (
        <img
          className="bg"
          src={heroBannerState?.media?.background?.content[0]?.url}
          onError={(e) =>
            (e.target.src = heroBannerState?.media?.background?.content[0]?.url)
          }
        />
      ) : (
        <></>
      )}
      <BannerContainer>
        {(heroBannerState?.heading?.text ||
          heroBannerState?.description?.text ||
          heroBannerState?.buttons) && (
          <TopSection>
            {heroBannerState?.heading?.text && (
              <LeftContainer textStyles={propStyle}>
                <Heading headingData={heroBannerState?.heading} />
              </LeftContainer>
            )}
            {(heroBannerState?.description?.text || heroBannerState?.buttons) && (
              <RightContainer textStyles={propStyle}>
                {heroBannerState?.description?.text && (
                  <Description descriptionData={heroBannerState?.description} />
                )}
                {heroBannerState?.button && (
                  <ButtonContainer>
                    {heroBannerState?.button?.content?.map((btnData, idx) => (
                      <Button
                        key={btnData?.id || idx}
                        btnData={btnData}
                        primaryButton={propStyle?.buttonPrimary}
                        secondaryButton={propStyle?.buttonSecondary}
                        callback={openForm}
                      />
                    ))}
                  </ButtonContainer>
                )}
              </RightContainer>
            )}
          </TopSection>
        )}
        {heroBannerState?.media?.foreground?.content && (
          <BottomSectionBanner
            IS_MEDIA_URL={
              heroBannerState?.media?.foreground?.content.length > 0 ? true : false
            }
          >
            {renderMediaComponent(heroBannerState?.media?.foreground)}
          </BottomSectionBanner>
        )}
      </BannerContainer>
    </HeroBannerParent>
  );
}

export default ModernHeroBanner4;
